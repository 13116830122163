import React from "react";
import Index from "../components/termsAndConditions";

const privacy = () => {
  return (
    <>
      <Index />
    </>
  );
};

export default privacy;
