import React from "react";
import Footer from "../home/footer/Footer";
import Navbar from "../home/navbar/Navbar";
import TermsAndConditions from "./termsAndConditions";
import SEO from "../Seo";
import * as styles from "./termsAndConditions.module.css";

const Index = () => {
  return (
    <div className={styles.componentWrapper}>
      <SEO titleStub={`Terms And Conditions`} urlStub={`/terms-conditions/`} />
      <Navbar />
      <TermsAndConditions />
      <Footer />
    </div>
  );
};

export default Index;
